import React from "react"

import Layout from "../components/layout.js"
import SEO from "../components/seo-component.js"

import { useLocation } from '@reach/router'; 

const NotFoundPage = () => { 
    
    // console.log(location)  
    try { 
        const location = useLocation(); //https://stackoverflow.com/questions/50976388/how-do-i-get-the-current-url-in-gatsby
        console.log("dpt 404 debug - location - "+location.href)
        console.log(location)
    }
    catch(err) {
        console.log("dpt 404 debug - error - couldn't get error")
        console.log(err)
    }

    
    return (
    <Layout>
        <SEO title="404: Not found"/>
        <div className="container">
            <h1>NOT FOUND</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>
    </Layout>
)}

export default NotFoundPage
